<template>
  <nav class="navbar navbar-expand-lg navbar-bg-color ">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar, moveX: $sidebar.showSidebar }">
          <button type="button" class="navbar-toggler" @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1 navbar-btn-color"></span>
            <span class="navbar-toggler-bar bar2 navbar-btn-color"></span>
            <span class="navbar-toggler-bar bar3 navbar-btn-color"></span>
          </button>
        </div>

        <a class="navbar-brand text-center" href="javascript:void(0)">
          <span data-bs-toggle="tooltip" class="d-block d-lg-none navbar-txt-color" data-bs-placement="bottom" title="Tooltip on bottom" >{{ profileStore }} <br></span>
           <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on bottom" class="navbar-txt-color">{{ $t(`routes.${this.$route.name}`) }}</span>
        </a>

        <a class="navbar-brand store-name d-none d-lg-block" href="javascript:void(0)">
          <span data-bs-toggle="tooltip"  data-bs-placement="bottom" title="Tooltip on bottom" class="navbar-txt-color">{{ profileStore }}</span>
        </a>
        <div class="w-25 d-flex justify-content-end collapse show text-left">
        <div class="col-12 d-none d-md-block text-right">
            <small class="text-primary text-right">{{ profileEmail }}</small><br>
            <small class="text-primary text-right">{{ profileName }}</small>
        </div>
          <ul class="navbar-nav ml-auto">
            <drop-down>
              <a href="#" class="dropdown-toggle nav-link navbar-txt-color" data-toggle="dropdown">
                <div class="photo shadow">
                  <img
                    v-if="profilePicture"
                    :src="profilePicture"
                    @error="replaceByDefault"
                    class="profile-img"
                    alt="Profile Photo"
                    width="100%"
                    height="100%"
                  />
                  <img v-else src="@/assets/img/anime3.png" class="profile-img" alt="Profile Photo" width="100%" height="100%"/>
                </div>
                <strong class="caret d-none d-lg-block d-xl-block "></strong>
              </a>
              <ul class="dropdown-menu dropdown-navbar bg-white userModal">
                <li class="nav-link mb-1 mb-lg-0 d-block d-md-none text-center">
                  <a href="javascript:void(0)" class="nav-item dropdown-item profile-container-mobile">
                    <small class="text-primary">{{ profileEmail }}</small><br>
                    <small class="text-primary">{{ profileName }}</small>
                  </a>
                </li>
                <li class="nav-link mb-2 mb-md-1 mb-lg-0" @click="goToEditProfile">
                  <a href="javascript:void(0)" class="nav-item dropdown-item">
                    <em class="tim-icons icon-single-02"></em>
                    {{ $t("myProfile") }}</a
                  >
                </li>
                <li class="nav-link mb-2 mb-md-1 mb-lg-0" @click="logout()">
                  <a href="javascript:void(0)" class="nav-item dropdown-item">
                    <em class="tim-icons icon-button-power"></em>
                    {{ $t("logout") }}</a
                  >
                </li>
              </ul>
            </drop-down>
          </ul>
        </div>
      </div>

    </div>
  </nav>
</template>
<script>
import DropDown from "@/components/white-dashboard/Dropdown.vue";
import {mapActions, mapState} from "vuex";
import useLogout from "../Auth/useLogout";
import defaultImage from "../../assets/img/image_placeholder.jpg";

export default {
  components: {
    DropDown,
  },
  data() {
    return {
      route: "/auth/logout",
      profilePicture: null,
      profileName : null,
      profileEmail : null,
      profileStore :null
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    async logout() {
      if (!this.hasChangeForm) {
        const response = await useLogout();
        if (response && response.data.status === "success") await this.$router.push({name: "login"});
      } else {
        await this.$router.push({name: "login"});
      }
    },
    async goToEditProfile() {
      await this.$store.dispatch("setPreviousPage", location.pathname);
      await this.$router.push("/profile");
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
    ...mapActions(["setPreviousPage"])
  },
  computed:{
    ...mapState(["hasChangeForm"]),
  },
  mounted() {
    if ( localStorage.getItem("profilePicture") )
      this.profilePicture =
        localStorage.getItem("profilePicture") !== "null"
          ? localStorage.getItem("profilePicture")
          : null;
    if ( localStorage.getItem("user") ){
      const pro         = JSON.parse(localStorage.getItem("user"));
      this.profileName  = pro.name;
      this.profileEmail = pro.email;
    }
    if ( localStorage.getItem("store") && localStorage.getItem("userRole") === "Manager" ){
      this.profileStore = localStorage.getItem("store")
    }else{
      this.profileStore = null
    }
  
  },
};
</script>

<style scoped>
.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.userModal {
  position: absolute !important;
  border-radius: 5px;
}
.navbar-wrapper {
  width: 100%;
  justify-content: inherit;
}
.navbar-toggle {
  width: 15%;
}
.navbar-brand {
  position: absolute;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w-25 {
  width: 25%;
}
.moveX {
  position: relative;
  left: 260px;
  z-index: 10
}

.store-name{
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
}

.profile-container-mobile:after {
    content: '';
    top: 62px;
    position: absolute;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background: rgb(191 191 191);
}

.navbar-bg-color{
  background-color: #f5f6fa !important;
  position: fixed !important;
  border-top: 2px solid #00bf9a;
  top: 0;
  padding-bottom: 0px;

}
.navbar-txt-color{
  color: #222a42 !important;
}
.navbar-btn-color{
  color: #222a42 !important;
  background: #222a42 !important;
}

@media (max-width: 768px) {
  .dropdown-menu.dropdown-navbar {
      left: -131px;
  }
  .dropdown-menu.dropdown-navbar:before, .dropdown-menu.dropdown-navbar:after {
      right: 33px;
  }
  .navbar-txt-color{
    color: #222a42 !important;
  }
  .navbar-btn-color{
    color: #222a42 !important;
    background: #222a42 !important;
  }
}
@media (min-width: 314px) {
  .navbar-toggler {
    background: #f7f6f655;
    border-radius: 25px;
    height: 50px;
    width: 50px;
  }
  .navbar-txt-color{
    color: #222a42 !important;
  }
  .navbar-btn-color{
    color: #222a42 !important;
    background: #222a42 !important;
  }
}
@media (min-width: 412px) {
  .navbar-toggler {
    background: transparent;
    border-radius: none;
  }
  .navbar-txt-color{
    color: #222a42 !important;
  }
  .navbar-btn-color{
    color: #222a42 !important;
    background: #222a42 !important;
  }
}
</style>
