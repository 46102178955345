<template>
  <div class="wrapper shadow" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="backgroundColor" title="AS" :abv="null">
      <sidebar-link
        v-for="(route, index) in routesAvaibles"
        :to="route.url"
        :key="index"
      >
        <i :class="[route.icon, 'tim-icons']"></i>
        <template>
          <p @click="changeRouteFromMenu()" class="break-space">{{ $tc(route.title, 2) }}</p>
        </template>
      </sidebar-link>
    </side-bar>

    <div class="main-panel" :data="backgroundColor">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<style lang="scss">
.break-space {
  padding-left: 45px;
  white-space: break-spaces !important;
}
</style>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./DashboardContent.vue";
import SideBar from "@/components/white-dashboard/SidebarPlugin/SideBar.vue";
import SidebarLink from "@/components/white-dashboard/SidebarPlugin/SidebarLink.vue";
import { mapState } from "vuex";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    SideBar,
    SidebarLink,
  },
  data() {
    return {
      backgroundColor: "green",
    };
  },
  computed: {
    isManager() {
      return this.managerRole == localStorage.getItem("userRole");
    },
    isAdmin() {
      return this.adminRole == localStorage.getItem("userRole");
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    routesAvaibles() {
      const userRole = localStorage.getItem("userRole");
      return this.routesDashboard.filter((route) =>
        route.canView.includes(userRole)
      );
    },
    ...mapState(["isEmployee", "isAdmin", "isManager", "routesDashboard"]),
  },
  methods: {
    changeRouteFromMenu() {
      setTimeout(() => {
        this.toggleSidebar();
      }, 1);
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
