<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        &copy;
        {{ new Date().getFullYear() }}
        <a :href="itexonUrl" target="_blank" rel="noopener noreferrer"
          >ITEXON</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'content-footer',
  data: () => {
    return {
      itexonUrl: process.env.VUE_APP_ITEXON_URL || 'https://itexon.es/',
    }
  }
}
</script>
